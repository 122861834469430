import React from 'react'
import {Grid, Segment} from "semantic-ui-react";
import 'fomantic-ui-css/semantic.min.css';
import LoginForm from "../forms/LoginForm";

const Login = () => {


  return (
    <Grid centered columns={1}>
      <Grid.Row>
        <Grid.Column>
          <Segment>
            <LoginForm/>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
};

export default Login