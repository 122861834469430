import {Container, Header} from "semantic-ui-react";
import React from "react";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import 'fomantic-ui-css/semantic.min.css';
import SearchTasks from './components/SearchTasks'
import Login from './components/Login'
import Signup from './components/Signup'
import ResetPwd from "./components/ResetPwd";
import RequestPwdReset from "./components/RequestPwdReset";

const App = () => {

  return (
    <Container textAlign="center">
      <Header as='h1' textAlign='center'><a href="/">Kurt's Postings Tracker</a></Header>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<SearchTasks />}/>
          <Route path='/index' element={<SearchTasks />}/>
          <Route path='/login' element={<Login />}/>
          <Route path='/logout' element={<Login />}/>
          <Route path='/signup' element={<Signup />}/>
          <Route path='/reset_pwd' element={<ResetPwd />}/>
        <Route path='/request_reset_pwd' element={<RequestPwdReset />}/>
        </Routes>
      </BrowserRouter>
    </Container>
  )
};

export default App
