import React from 'react'
import {Grid, Segment} from "semantic-ui-react";
import 'fomantic-ui-css/semantic.min.css';
import RequestPwdResetForm from "../forms/RequestPwdResetForm";

const RequestPwdReset = () => {

  return (
    <Grid centered columns={1}>
      <Grid.Row>
        <Grid.Column>
          <Segment>
            <RequestPwdResetForm/>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
};

export default RequestPwdReset
