import React from 'react'
import {Grid, Segment} from "semantic-ui-react";
import ResetPwdForm from "../forms/ResetPwdForm";
import { useSearchParams } from "react-router-dom";

const ResetPwd = () => {
  let [searchParams] = useSearchParams();

  return (
    <Grid centered columns={1}>
      <Grid.Row>
        <Grid.Column>
          <Segment>
            <ResetPwdForm queryParams={searchParams} />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
};

export default ResetPwd
